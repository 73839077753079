import React, { useState, useRef } from 'react'
import { message, Modal } from 'antd'
import axios from 'axios'
import { strapiURL } from '../../config'
import { logout } from '../../redux/actions/user'
import { CloseModalIcon } from '../icons'
import cookie from 'react-cookies'
import { useDispatch } from 'react-redux'

const UpdateEmail = ({ modalOpen, userProfile, setModalOpen }) => {
  const emailInputRef = useRef(null)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)

  const closeModalHandler = () => {
    setLoading(false)
    setModalOpen(false)
  }
  const emailChangeHandler = () => {
    let value = (emailInputRef.current.value || '').toLowerCase()
    if (!value || value === userProfile?.username) {
      message.error('Please enter a new email address.')
      return
    } else {
      setEmailLoading(true)
      let data = {
        userId: userProfile?.user?.auth0_user_id,
        email: value,
      }
      axios
        .post(strapiURL + '/auth0/user/update/email', data)
        .then(res => {
          message.success('Updated')
          setEmailLoading(false)
          cookie.remove('user', { path: '/' })
          setTimeout(() => {
            if (typeof window !== 'undefined') {
              window.location.href = '/'
            }
          }, 500)
          dispatch(logout())
        })
        .catch(err => {
          emailInputRef.current.value = userProfile.username
          message.error('User with this email already exist')
          setEmailLoading(false)
        })
    }
  }
  return (
    <Modal
      width={560}
      footer={null}
      onCancel={closeModalHandler}
      visible={modalOpen}
      okText="Save"
      className="p-0 candidateAlertModal shadow-lg password-modal mx-auto w-[90%] md:w-[40%]"
    >
      <div className="bg-white px-6 py-8 rounded">
        <div className="w-full">
          <i className="close-modal-icon" onClick={closeModalHandler}>
            <CloseModalIcon />
          </i>
          <div className="w-full relative theme-form-item">
            <input
              ref={emailInputRef}
              defaultValue={userProfile?.user?.email || ''}
              placeholder="Enter Your Email Address"
              className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none w-full"
            />

            <div className="absolute right-0 top-0 bottom-0 flex items-center">
              <button
                onClick={emailChangeHandler}
                type="button"
                className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2 "
              >
                {emailLoading ? 'Updating...' : 'Update'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default UpdateEmail
