import React, { useState } from 'react'
import { Form, message, Modal, Input } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { auth0Roles, strapiURL } from '../../config'
import { logout } from '../../redux/actions/user'
import { CloseModalIcon } from '../icons'
const UpdatePasswordModal = ({ modalOpen, userProfile, setModalOpen }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const closeModalHandler = () => {
    setLoading(false)
    setModalOpen(false)
    form.resetFields()
  }

  const onFinish = values => {
    const regex =
      /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])).{8,16}$/
    if (values?.new_password !== values?.confirm_new_password) {
      message.error('Password not matched')
      return
    }

    if (
      values.confirm_new_password.length < 8 ||
      !values.confirm_new_password.match(regex)
    ) {
      message.error(
        'Your password must contain at least 8 characters including at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*).'
      )
      return
    }
    setLoading(true)
    axios
      .post(strapiURL + '/auth0/user/update/password', {
        old_password: values?.old_password,
        new_password: values?.new_password,
        userId: userProfile?.user?.auth0_user_id,
        email: userProfile?.user?.username,
      })
      .then(res => {
        message.success('Your password has been changed successfully.')
        setLoading(false)
        closeModalHandler()
      })
      .catch(err => {
        message.error('Wrong password')
        setLoading(false)
      })
  }
  return (
    <Modal
      width={560}
      footer={null}
      onCancel={closeModalHandler}
      visible={modalOpen}
      //   visible={true}
      okText="Save"
      className="p-0 candidateAlertModal shadow-lg password-modal mx-auto w-[90%] md:w-[40%]"
    >
      <div className="bg-white px-6 py-8 rounded">
        <div className="w-full">
          <i className="close-modal-icon" onClick={closeModalHandler}>
            <CloseModalIcon />
          </i>
          <Form
            layout="vertical"
            form={form}
            name="namedUserForm"
            onFinish={onFinish}
          >
            <p className="text-center font-sans font-semibold">
              Update your password
            </p>
            <Form.Item
              className="theme-form-item-password"
              name="old_password"
              label="Enter Your Old Password"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
              ]}
            >
              <Input.Password
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              className="theme-form-item-password"
              name="new_password"
              label="Enter Your New Password"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
                {
                  validator: (rule, value, callback) => {
                    if (value === form.getFieldValue('old_password')) {
                      callback(
                        'Same password as current one. New password is required.'
                      )
                    } else {
                      callback()
                    }
                  },
                },
              ]}
            >
              <Input.Password
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              className="theme-form-item-password"
              name="confirm_new_password"
              label="Confirm Your New Password"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
                {
                  validator: (rule, value, callback) => {
                    if (value !== form.getFieldValue('new_password')) {
                      callback('Please input same password with new password.')
                    } else {
                      callback()
                    }
                  },
                },
              ]}
            >
              <Input.Password
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <div className="flex items-center justify-center mt-8">
              <button
                type="submit"
                className="bg-voyage py-3 px-6 text-white font-bold rounded text-xs font-sans mr-4"
              >
                {loading ? 'Updating...' : 'Update'}
              </button>
              <button
                onClick={() => closeModalHandler()}
                type="button"
                className="bg-bgSecondaryButton py-3 px-6 text-voyage font-bold rounded text-xs font-sans"
              >
                Cancel
              </button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  )
}
export default UpdatePasswordModal
