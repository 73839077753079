import React from 'react'
import {
  permissionsHandler,
  _subscriptionsSutructureData,
} from '../../../src/functions'

import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
const CreditDetail = ({ user }) => {
  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)
  return (
    <div className="flex flex-col lg:flex-row gap-6 xl:gap-8 mt-6 xl:mt-8">
      <div className="flex-1 rounded-lg bg-white shadow-profileCard py-6 px-4 flex justify-between items-center">
        <span className="font-sans font-bold text-sm">
          Job Credits Remaining
        </span>
        {!products?.subscriptionPurchased && !products?.singleJob ? (
          <Link to="/employer/membership/">
            <button className="bg-voyage py-3 px-8 font-sans text-white text-xs font-bold rounded">
              Purchase
            </button>
          </Link>
        ) : products?.subscriptionPurchased ? (
          <p className="inline-block bg-[#DCE9EE] text-[#00567C] text-location text-sm font-sans mb-0 py-2 px-4 font-semibold rounded">
            Unlimited
          </p>
        ) : (
          <p className="inline-block bg-[#DCE9EE] text-[#00567C] text-location text-sm font-sans mb-0 py-2 px-4 font-semibold rounded">
            {products?.singleJob}
          </p>
        )}
      </div>
      <div className="flex-1 rounded-lg bg-white shadow-profileCard py-6 px-4 flex justify-between items-center">
        <span className="font-sans font-bold text-sm">
          Featured Job Credits
        </span>

        <p className="inline-block bg-[#DCE9EE] text-[#00567C] text-location text-sm font-sans mb-0 py-2 px-4 font-semibold rounded">
          {products?.featuredJob ? products?.featuredJob : '0'}
        </p>
      </div>
    </div>
  )
}
export default CreditDetail
