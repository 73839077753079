import React from 'react'
import AccountSettingsComponent from '../../components/employerDashboard/accountSettings'
import Layout from '../../components/employerDashboard/layout'
const EmployerAccountPage = ({ location }) => {
  return (
    <Layout title="Account Settings | MedReps.com" location={location}>
      <AccountSettingsComponent />
    </Layout>
  )
}
export default EmployerAccountPage
